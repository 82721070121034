<template>
  <div>
    <en-table-layout
      tips
      @selection-change="handleSelectionChange"
      :tableData="tableData.data"
      :loading="loading"
      ref="multipleTable"
      :rowKey="(row)=>{return row.shop_id}"
    >
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--商户分类-->
          <div class="conditions">
            <div>商户分类：</div>
            <el-select
              class="ipt-default"
              style="width:100px"
              v-model="advancedForm.shop_status"
              clearable
            >
              <el-option value="0" label="全部"></el-option>
              <el-option value="1" label="企业"></el-option>
              <el-option value="2" label="个人"></el-option>
            </el-select>
          </div>
          <!--关键字-->
          <div class="conditions">
            <div>关键字选择：</div>
            <el-select class="ipt-default" v-model="advancedForm.keyword_type" clearable>
              <el-option value="shop_no" label="商户编号"></el-option>
              <el-option value="shop_name" label="商户名称"></el-option>
              <el-option value="link_name" label="联系人姓名"></el-option>
              <el-option value="link_phone" label="联系人手机号查询"></el-option>
            </el-select>
            <el-input
              class="ipt-default"
              style="width: 200px"
              v-model="advancedForm.keyword"
              placeholder="请输入关键字进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格上部按钮 -->
      <div slot="toolbar">
        <el-button class="btn-default" @click="handleDialog(1)">群发短信</el-button>
        <el-button class="btn-default" @click="handleDialog(2)">群发站内信</el-button>
        <el-button class="btn-default" @click="handleDialog(3)">{{ btnAllFlag ? '取消全选' : '选择全部' }}</el-button>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column
          type="selection"
          :selectable="()=>{return !btnAllFlag}"
          :reserve-selection="true"
          width="55"
        ></el-table-column>
        <el-table-column prop="shop_no" label="商户编号" width="180"/>
        <el-table-column prop="shop_logo" label="商户LOGO" width="180">
          <template slot-scope="scope">
            <img :src="scope.row.shop_logo" class="img_class"/>
          </template>
        </el-table-column>
        <el-table-column prop="shop_name" label="商户名称" width="180"/>
        <el-table-column prop="link_name" label="联系人姓名" width="180"/>
        <el-table-column prop="link_phone" label="联系人手机号" width="180"/>
        <el-table-column label="分类" width="180">
          <template slot-scope="scope">
            {{ scope.row.shop_status.toString().includes('1') ? '企业' : '个体户' }}
          </template>
        </el-table-column>
        <el-table-column prop="link_weixin" label="微信号" width="180"/>
        <el-table-column prop="link_email" label="电子邮箱" width="180"/>
        <el-table-column label="创建日期" width="180">
          <template slot-scope="scope">{{ scope.row.create_date | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleScaledialog(scope.row)">佣金设置</el-button>
            <el-button size="mini" type="text" @click="handleScaleEdit(scope.row)">查看</el-button>
            <el-button size="mini" type="text" @click="handleDeleteAll(scope.row)">下架全部活动</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!-- 佣金比例设置 -->
    <el-dialog
      title="佣金设置"
      :visible.sync="dialogScaleVisible"
      width="650px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="scaleForm" ref="scaleForm" label-width="200px">
        <!--店铺名称-->
        <el-form-item label="店铺名称">
          <span>{{ scaleForm.shop_name }}</span>
        </el-form-item>
        <!--当前店铺分类-->
        <el-form-item label="当前店铺分类">
          <!-- <span>{{scaleForm.cat_first_name}}</span>
          <span>{{scaleForm.cat_second_name?scaleForm.cat_second_name:''}}</span>-->
          <span
            v-for="(item,index) in dialogScaleData"
            :key="item.id"
          >
          <template v-if="index">
            、{{ item.cat_second_id ? item.cat_second_name : item.cat_first_name }}
          </template>
          <template v-else>
            {{ item.cat_second_id ? item.cat_second_name : item.cat_first_name }}
          </template>
          </span>
        </el-form-item>
        <el-form-item label="佣金比例设置">
          <el-form-item
            v-for="item in dialogScaleData"
            :key="item.id"
            :label="item.cat_second_id?item.cat_second_name:item.cat_first_name"
            label-width="100px"
          >
            <el-input
              type="number"
              v-model="scaleForm[item.id]"
              class="ipt-default"
              style="width:100px"
            />
            %
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogScaleVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitScaleForm('scaleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--添加会员 dialog-->
    <el-dialog
      title="添加会员"
      :visible.sync="dialogAddMemberVisible"
      width="650px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addMemberForm"
        :rules="addMemberRules"
        ref="addMemberForm"
        label-width="100px"
        inline
      >
        <!--用户名-->
        <el-form-item label="用户名" prop="uname">
          <el-input v-model="addMemberForm.uname" :maxlength="20"></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item label="密码" prop="password">
          <el-input v-model="addMemberForm.password" :type="pwdType" :maxlength="20"></el-input>
          <span class="show-pwd" @click="pwdType = pwdType === 'password' ? 'text' : 'password'">
            <svg-icon :icon-class="pwdType === 'password' ? 'eye' : 'eye-open'"/>
          </span>
        </el-form-item>
        <!--昵称-->
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="addMemberForm.nickname" :maxlength="20"></el-input>
        </el-form-item>
        <!--性别-->
        <el-form-item label="性别" class="form-item-sex">
          <el-radio v-model="addMemberForm.sex" :label="1">男</el-radio>
          <el-radio v-model="addMemberForm.sex" :label="0">女</el-radio>
        </el-form-item>
        <!--生日-->
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
            v-model="addMemberForm.birthday"
            type="date"
            :editable="false"
            value-format="timestamp"
            placeholder="选择生日"
            :picker-options="{disabledDate: MixinDisabledDate}"
          ></el-date-picker>
        </el-form-item>
        <!--邮箱-->
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addMemberForm.email"></el-input>
        </el-form-item>
        <!--地区-->
        <el-form-item label="地区" prop="region" class="form-item-region">
          <en-region-picker
            :api="MixinRegionApi"
            :default="defaultRegion"
            @changed="(object) => { addMemberForm.region = object.last_id }"
          />
        </el-form-item>
        <!--手机号码-->
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model.number="addMemberForm.mobile" :maxlength="11"></el-input>
        </el-form-item>
        <!--详细地址-->
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="addMemberForm.address" :maxlength="50"></el-input>
        </el-form-item>
        <!--固定电话-->
        <el-form-item label="固定电话">
          <el-input v-model.number="addMemberForm.tel" :maxlength="20"></el-input>
        </el-form-item>
        <!--邮编-->
        <el-form-item label="邮编">
          <el-input v-model.number="addMemberForm.zip" :maxlength="6"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogAddMemberVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitAddMemberForm('addMemberForm')">确 定</el-button>
      </div>
    </el-dialog>

    <!--群发短信 dialog-->
    <el-dialog
      title="群发短信"
      :visible.sync="dialogMessageVisible"
      width="650px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="MessageVisibleForm"
        :rules="MessageVisibleRules"
        ref="MessageVisibleForm"
        label-width="130px"
      >
        <!--发送对象-->
        <el-form-item label="发送对象">
          <span v-if="!btnAllFlag">
            共
            <span>{{ num }}</span>个商户
          </span>
          <span v-else>
            <span>所有商户</span>
          </span>
        </el-form-item>
        <!--短信内容-->
        <el-form-item label="短信模板选择" prop="modelid">
          <el-select v-model="MessageVisibleForm.modelid" placeholder="请选择短信模板">
            <el-option
              v-for="item in messageDiytemplate"
              :key="item.id"
              :label="item.tpl_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogMessageVisible = false">取 消</el-button>
        <el-button
          class="btn-default-dialog-yes"
          @click="submitMessageVisibleForm('MessageVisibleForm')"
        >确 定
        </el-button>
      </div>
    </el-dialog>
    <!--群发站内信 dialog-->
    <el-dialog
      title="群发站内信"
      :visible.sync="dialogZhanneiMessageVisible"
      width="650px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="ZhanneiMessageVisibleForm"
        :rules="ZhanneiMessageVisibleRules"
        ref="ZhanneiMessageVisibleForm"
        label-width="100px"
      >
        <!--发送对象-->
        <el-form-item label="发送对象">
          <span v-if="!btnAllFlag">
            共
            <span>{{ num }}</span>个商户
          </span>
          <span v-else>
            <span>所有商户</span>
          </span>
        </el-form-item>
        <!--标题-->
        <el-form-item label="标题" prop="title">
          <el-input v-model="ZhanneiMessageVisibleForm.title" :maxlength="50"></el-input>
        </el-form-item>
        <!--短信内容-->
        <el-form-item label="短信内容" prop="message">
          <el-input type="textarea" v-model="ZhanneiMessageVisibleForm.message"></el-input>
        </el-form-item>
      </el-form>
      <!-- / -->
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogZhanneiMessageVisible = false">取 消</el-button>
        <el-button
          class="btn-default-dialog-yes"
          @click="submitMessageVisibleForm('ZhanneiMessageVisibleForm')"
        >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
import * as API_Shop from "@/api/shop";
import * as API_Sms from "@/api/sms";
import {RegExp} from "@/libs/common/utils";

export default {
  name: "memberList",
  watch: {
    "addMemberForm.region"(next, prev) {
      if (next && next.length) {
        this.$refs.addMemberForm.clearValidate("region");
      }
    },
  },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      // 地区id数组
      defaultRegion: null,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        // shop_disable: 'OPEN',
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        shop_type: "", // 商品分类
        keyword_type: "", // 根据关键字
        keyword: "", // 根据关键字搜索
      },

      /** 添加会员 dialog */
      dialogAddMemberVisible: false,

      /** 添加会员 表单数据 */
      addMemberForm: {},

      /** 添加会员 表单规则 */
      addMemberRules: {
        uname: [
          this.MixinRequired("请输入用户名！"),
          {
            min: 4,
            max: 20,
            message: "长度在 4 到 20 个字符",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.userName.test(value)) {
                callback(new Error("请输入汉字、字母、数字、“-”、“_”的组合！"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          this.MixinRequired("请输入密码！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.password.test(value)) {
                callback(new Error("密码格式有误！"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        nickname: [
          this.MixinRequired("请输入昵称！"),
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        birthday: [this.MixinRequired("请选择生日！")],
        email: [
          {
            validator: (rule, value, callback) => {
              if (value && !RegExp.email.test(value)) {
                callback(new Error("电子邮箱格式有误！"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        mobile: [
          this.MixinRequired("请输入手机号码！"),
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error("手机格式格式有误！"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        address: [
          this.MixinRequired("请输入详细地址！"),
          {
            max: 50,
            message: "最长50个字符",
            trigger: "blur"
          },
        ],
        region: [this.MixinRequired("请选择地区！")],
      },
      pwdType: "password",
      /** 佣金比例设置 dialog */
      dialogScaleVisible: false,
      /** 分类数组 */
      dialogScaleData: [],
      /** 佣金比例设置 表单数据 */
      scaleForm: {},
      /** 群发短信 */
      dialogMessageVisible: false,
      MessageVisibleForm: {
        type: 1,
        sendall: "",
        modelid: "",
      },
      MessageVisibleRules: {
        modelid: [
          {
            required: true,
            message: "请选择短信模板",
            trigger: "change"
          },
        ],
      },
      /** 群发站内信 */
      dialogZhanneiMessageVisible: false,
      ZhanneiMessageVisibleForm: {
        type: 1,
        sendall: "",
      },
      ZhanneiMessageVisibleRules: {
        title: [
          {
            required: true,
            message: "请填写站内信标题",
            trigger: "blur"
          },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        message: [
          {
            required: true,
            message: "请填写内容",
            trigger: "blur"
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },

      ids_list: [], // 复选框数据
      // 记录选择的数据列数
      num: 0,
      // 记录是否点击的选择全部
      btnAllFlag: false,
      // messageDiytemplate  短信模板
      messageDiytemplate: [],
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile;
    },
  },
  mounted() {
    this.GET_ShopList();
    // 首先获取短信模板
    API_Sms.getMessageDiytemplate().then((res) => {
      console.log(res, "获取短信模板");
      this.messageDiytemplate = res;
    });
  },
  methods: {
    /** 当选择项发生变化 */
    handleSelectionChange(val) {
      this.ids_list = val.map((item) => item.shop_id);
      this.num = this.ids_list.length;
    },
    // 群发
    handleDialog(val) {
      if (val !== 3 && this.ids_list.length === 0) {
        this.$message.error("请勾选商户！");
        return;
      }
      // val  1 群发短信  2群发站内信 3 选择全部
      if (val === 1) {
        this.dialogMessageVisible = true;
      } else if (val === 2) {
        this.dialogZhanneiMessageVisible = true;
      } else {
        var _this = this;
        this.btnAllFlag = !this.btnAllFlag;
        // 选中数据回显
        _this.$nextTick(() => {
          _this.$refs.multipleTable.selectCheckAll(_this.btnAllFlag);
        });
      }
    },
    /** 查看 */
    handleScaleEdit(row) {
      this.$router.push({
        path: `/merchant/merchant-detail/${row.shop_id}`,
      });
    },
    /** 下架所有活动 */
    handleDeleteAll(row) {
      this.$confirm("确认要下架该商户全部的活动吗？", "提示", {
        type: "warning",
        cancelButtonClass: "btn-default-dialog-no",
        confirmButtonClass: "btn-default-dialog-yes",
      }).then(() => {
        API_Shop.closeShop(row.shop_id).then((res) => {
          this.$message.success("下架成功");
        }).catch(() => {
        });
      }).catch(() => {
      });
    },
    /** 佣金比例设置确定 */
    submitScaleForm() {
      // 修改佣金比例
      var shopCategories = [];
      var obj = {};
      var that = this;
      Object.keys(that.scaleForm).forEach(function (key) {
        // console.log("key ", key, ": ", that.scaleForm[key]);
        if (key !== 'shop_name') {
          obj.id = Number(key);
          obj.commission_rate = that.scaleForm[key];
          shopCategories.push(obj);
          obj = {};
        }
      });
      API_Member.editShopsCommission(shopCategories)
        .then((res) => {
          this.$message.success("修改成功");
          this.dialogScaleVisible = false;
          this.GET_ShopList();
        })
        .catch((_) => {
        });
    },
    /** 佣金比例设置 */
    handleScaledialog(row) {
      API_Member.getShopCategory(row.shop_id, {shop_id: row.shop_id}).then(
        (res) => {
          var obj = {};
          res.map((item) => {
            obj[item.id] = item.commission_rate;
          });
          this.dialogScaleData = res;
          obj.shop_name = row.shop_name
          this.scaleForm = obj;
        }
      );
      this
      this.dialogScaleVisible = true;
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_ShopList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_ShopList();
    },

    handleDeleteMember(index, row) {
      this.$confirm("确定要禁用这个会员吗？", "提示", {type: "warning"})
        .then(() => {
          API_Member.deleteMember(row.member_id).then(() => {
            this.$message.success("禁用成功！");
            this.GET_ShopList();
          });
        })
        .catch(() => {
        });
    },

    /** 性别格式化 */
    formatterSex(row, column, cellValue) {
      return row.sex === 1 ? "男" : "女";
    },

    /** 添加会员 */
    handleAddMember() {
      this.addMemberForm = {
        sex: 1,
        region: this.addMemberForm.region || []
      };
      this.dialogAddMemberVisible = true;
    },

    /** 搜索事件触发 */
    searchEvent() {
      delete this.params["shop_no"];
      delete this.params["shop_name"];
      delete this.params["link_name"];
      delete this.params["link_phone"];
      this.params.page_no = 1;
      this.params[this.advancedForm.keyword_type] = this.advancedForm.keyword;
      this.params.shop_status = this.advancedForm.shop_status === '0' ? '' : this.advancedForm.shop_status;
      this.GET_ShopList();
    },

    /** 提交群发短信表单 */
    submitMessageVisibleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.btnAllFlag) {
            this[formName].sendall = "ALL";
          } else {
            this[formName].sendall = "";
          }
          if (formName === "ZhanneiMessageVisibleForm") {
            /** 发送站内信 */
            API_Sms.putDlyqf(this.ZhanneiMessageVisibleForm, this.ids_list.toLocaleString())
              .then((res) => {
                this.dialogZhanneiMessageVisible = false;
                this.$message.success("发送成功");
                this.$refs[formName].resetFields();
              })
              .catch((_) => {
                this.dialogZhanneiMessageVisible = false;
              });
            // console.log(this.ZhanneiMessageVisibleForm);
          } else {
            console.log(this.MessageVisibleForm, "this.MessageVisibleForm,");
            /** 群发短信 */
            API_Sms.addGroupmessage(this.MessageVisibleForm, this.ids_list)
              .then((res) => {
                this.dialogMessageVisible = false;
                this.$message.success("发送成功");
                this.$refs[formName].resetFields();
              })
              .catch((_) => {
                this.dialogMessageVisible = false;
              });
          }
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 提交添加会员表单 */
    submitAddMemberForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = this.MixinClone(this.addMemberForm);
          params.birthday = parseInt(params.birthday / 1000);
          API_Member.addMember(params).then(() => {
            this.dialogAddMemberVisible = false;
            this.$message.success("添加成功！");
            this.GET_MemberList();
            this.addMemberForm = {};
            this.defaultRegion = [];
          });
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },

    /** 获取商户列表 */
    GET_ShopList() {
      this.loading = true;
      const {params} = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Shop.getShopList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
          // 判断是否全选
          if (this.btnAllFlag) {
            // 选中数据回显
            this.$nextTick(() => {
              this.$refs.multipleTable.selectCheckAll(this.btnAllFlag);
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.img_class {
  width: 50px;
  height: 50px;
}

.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}

::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}

div.toolbar-btns {
  display: contents;
}
</style>
